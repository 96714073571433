<template>
	<section class="improvement">
		<div class="improvement__container">
			<section class="when --width-144">
				<h2 class="i-h2-style">{{ content.title }}</h2>

				<div class="improvement__content">
					<div
						v-if="this.$store.state.isMobile === false"
						v-for="(box, i) in content.list"
						:key="i"
						class="improvement__box"
					>
						<p class="p-style" v-html="box.text"/>
					</div>

					<Slider v-else
					        carouselType="improvement"
							:slides="content.list"
					/>
				</div>
			</section>

			<div class="improvement__image">
				<img src="../../../assets/images/design/P&SD_usluga3/02_picture.webp" alt="pic">
			</div>

			<section class="how --width-144">
				<h2 class="i-h2-style">{{ content.title2 }}</h2>

				<div class="improvement__content">
					<div
						v-for="(box, i) in content.list2"
						:key="i"
						class="improvement__box"
					>
						<p class="p-style" v-html="box"/>
					</div>
				</div>
			</section>

			<section class="improvement__product --width-144">
				<h2 class="i-h2-style">{{ content.product.title }}</h2>
				<p class="p-style">{{ content.product.text }}</p>
				<span class="i-span-custom">{{ content.product.subtext }}</span>

				<ul class="product__list">
					<li
						v-for="item in content.product.list"
						:key="item.number"
						class="product__list-item"
					>
						<span class="i-number">{{ item.number }}</span>
						<p class="p-style">{{ item.text }}</p>
					</li>
				</ul>
			</section>
		</div>
	</section>
</template>

<script>
export default {
	name: "Product Improvement",
	props: {
		content: {
			type: Object
		},
	}
}
</script>

<style src="./style.sass" scoped lang="sass"></style>

<style scoped lang="sass">
.--width-144
	width: 144em
	margin: 0 auto
	padding-left: 211px

	@include view('sm')
	@include view('md')
		width: 100%
		padding-left: 0

.i-h2-style
	font-size: 54px
	font-weight: $weight--semi-bold
	margin-bottom: 53px

	@include view('sm')
		font-size: 22px
</style>
