import routePath from "../router/routePath";

const design = [
	{
		id: routePath.DESIGN_AUDITS.path,
		text: 'Zarówno podczas badań jak i warsztatów poza standardowymi metodami badawczymi praktykujemy również metody autorskie, które pozwalają nam na bardziej skuteczne rozwiązywanie wybranych problemów i zagadnień. Jest to odpowiedź na dynamicznie zmieniające się potrzeby klientów oraz konieczność bardziej dogłębnego poznania istoty niektórych wyzwań.',
		summary: {
			title: 'Podsumowanie',
			list: [
				'Pozyskanie danych, które pozwolą na stworzenie strategii produktu cyfrowego, tak aby zmniejszyć współczynnik odrzuceń i zwiększyć konwersję;',
				'Lepsze poznanie swoich odbiorców, co pozwala na dostosowanie do nich produktu, jak i sposobu komunikacji;',
				'Oszczędności poprzez zminimalizowanie ryzyka nietrafionej inwestycji;',
				'Odkrycie obszarów, w których jest możliwość optymalizacji;',
				'Realne opinie klientów w stosunku do firmy oraz produktu cyfrowego;'
			]
		},
		list: [
			{
				title: 'Audyty',
				text: 'W zależności od potrzeb sprawdzamy funkcjonalność całej strony, aplikacji, systemu bądź też konkretnego wycinka produktu. Audyt może oznaczać również prześledzenie konkretnych ścieżek w celu znalezienia możliwości optymalizacji oraz poznania ewentualnych punktów zapalnych, które sprawiają, że obecny produkt nie konwertuje: mogą to być pozornie bezzasadne porzucenia koszyków albo niski procent udziału konsumentów w promocji, pomimo intensywnych wysiłków działu marketingu w celu uświadomienia klientom istnienia oferty, która jest dla nich atrakcyjna. Może to być także bardzo niewielkie zainteresowanie aplikacją firmy, pomimo jednoczesnego wzrostu sprzedaży bez udziału aplikacji. <br><br>Poprzez audyt jesteśmy w stanie sprawdzić także procesy wewnątrzfirmowe, które są powiązane bezpośrednio z produktem cyfrowym, takie jak np. proces realizowania usługi, która ma być wspomagana produktem cyfrowym. Szukamy barier, które negatywnie wpływają na produkt, a często bezpośrednio na sprzedaż.',
				image: require('@/assets/images/design/P&SD_usluga1/02_audyty.webp')
			},
			{
				title: 'Badania',
				text: 'Głównym celem badań jest szeroko pojęte zrozumienie sytuacji, w jakiej znajduje się firma bądź jej produkt poprzez autentyczną weryfikację. Wykonujemy badania jakościowe, które pozwolą nam odkryć insighty i potrzeby oraz modele zachowania użytkowników, w momencie gdy idea bądź produkt są jeszcze we wczesnej fazie. Badania ilościowe stosowane są w przypadku, gdy produkt jest na zaawansowanym etapie realizacji. Pozwala to na przyjęcie skutecznej strategii rozwoju produktu oraz uwzględnienie potencjalnych przyszłych potrzeb. <br><br>Badania są podstawą dla wykonania skutecznego MVP, czyli podstawowej działającej wersji produktu, którą następnie będzie można rozbudowywać o dodatkowe funkcjonalności. Dzięki badaniom będziecie w stanie efektywnie korzystać i zarabiać na produkcie, który nie został jeszcze w pełni rozbudowany: najczęściej oznacza to zaoszczędzenie co najmniej kilku miesięcy. W ekstremalnym przypadku dla jednego z naszych klientów, który przyszedł do nas z prośbą o ewaluację swojego nieskończonego w ciągu 27 miesięcy produktu, stworzyliśmy pełnowartościowy produkt MVP w zaledwie 6 miesięcy. <br><br>Dobrze przeprowadzone badania pozwalają nam zaoszczędzić pieniądze poprzez zminimalizowanie ryzyka nietrafionej inwestycji. Dzięki poznaniu potrzeb i opinii dotychczasowych bądź potencjalnych odbiorców jesteśmy w stanie również uchronić się przed dodatkowymi kosztami po publikacji produktu, jeśli okaże się, że odpowiada on na potrzeby odbiorców nieadekwatnie i należy go całkowicie przeprojektować. W przypadku, gdy taki produkt został już oficjalnie udostępniony użytkownikom, koszt naprawiania produktu bywa nawet kilkudziesięciokrotnie wyższy niż w przypadku realizacji produktu opartego na badaniach, nie wspominając o kosztach związanych z niską konwersją i stratą wizerunkową.',
				image: require('@/assets/images/design/P&SD_usluga1/03_badania.webp'),
				imageMobile: require('@/assets/images/design/P&SD_usluga1/mobile.png'),
			},
			{
				title: 'Warsztaty',
				text: 'Poza badaniami podstawą naszych działań są również warsztaty, jakie przeprowadzamy zarówno z osobami odpowiedzialnymi w firmie za realizację produktu, jak i tymi, które będą z niego korzystać. <br><br>Pomagamy wydobyć insighty i ukryte informacje oraz usystematyzować zdobytą wiedzę w formie wniosków, które następnie można przełożyć na poszczególne wskaźniki. Warsztaty można przeprowadzić zależnie od potrzeb na każdym etapie projektu.',
				image: require('@/assets/images/design/P&SD_usluga1/04_warsztaty.webp')
			},
			{
				title: 'Konsultacje',
				text: 'Mamy świadomość, że każde przedsięwzięcie na styku technologii oraz biznesu w kontekście realizacji celów nie należy do łatwego zadania. Mnogość rozwiązań, skomplikowane terminy, metody, platformy, języki programowania oraz kolejne kroki, a także ewentualne możliwości zdają się nie mieć końca. Dlatego też wychodząc naprzeciw tym wyzwaniom w każdym momencie projektu, a także przed jego rozpoczęciem, służymy pomocą we wszystkich zagadnieniach, w jakich się specjalizujemy. <br><br>Chętnie odpowiemy na pytania oraz doradzimy, w jaki sposób sprostać wyzwaniom.',
				image: require('@/assets/images/design/P&SD_usluga1/05_konsultacje.webp')
			},
		],
	},
	{
		id: routePath.DESIGN_RESEARCH.path,
		summary: {
			title: 'Dzięki strategii',
			list: [
				'W przypadku prac nad poprawą użyteczności istniejącego produktu pozwoli Wam to na usystematyzowanie dotychczasowej pracy i uporządkowanie dalszego procesu realizacji projektu;',
				'Będziecie w stanie dopasować dzięki temu kolejność działań i strategii wewnętrznych w przypadku bardziej rozbudowanych kampanii reklamowych i działań marketingowych;',
				'Otrzymacie przejrzystą informację, na jakim etapie powinniście uruchomić jakie zasoby, co pozwoli Wam  również oszacować związane z tym przyszłe koszty;',
				'Będziecie w stanie elastycznie podejść do dynamicznych zmian, jakie dzieją się na rynku poprzez szybką ewaluację produktu w dowolnym momencie;',
				'Będziecie w stanie ocenić, czy ryzyko, które chcecie podjąć, będzie warte oszacowanych kosztów;',
				'Otrzymacie skuteczny plan działania nad produktem osadzony w czasie;',
			]
		}
		,
		list: [
			{
				title: 'Research',
				text: 'Informacja to podstawa przemyślanych działań. Zbieramy dane, które są niezbędne, aby skutecznie poprowadzić projekt. Dobieramy metodykę, która pozwoli nam dotrzeć do potencjalnych rozwiązań, taką jak analiza rynku i konkurencji, desk research, zadania eventowe czy wywiady z użytkownikami. <br><br>W przypadku chęci stworzenia nowego innowacyjnego produktu rekomendujemy rozpoczęcie działań od researchu, które pozwoli na zorientowanie się czego potrzebują użytkownicy, jakie są mocne i słabe punkty konkurencji, oraz co trzeba zrobić aby zaangażować użytkowników. <br><br>Umożliwia to podjęcie skutecznych decyzji strategicznych, które pozwolą zachować konkurencyjność, przy jednoczesnym minimalizowaniu ryzyka biznesowego.',
				image: require('@/assets/images/design/P&SD_usluga2/02_research.webp')
			},
			{
				title: 'Strategia',
				text: 'Znając cele biznesowe oraz mając w rękach wyniki badań, opracowujemy możliwe kierunki rozwoju produktu cyfrowego. Wskazujemy na funkcjonalności, które powinny być zawarte w podstawowym produkcie MVP oraz te dodatkowe, które mogą być tworzone na dalszych etapach. Projektujemy również strategię działań i określamy wskaźniki, jakie będą potrzebne do monitorowania wzrostów.',
				image: require('@/assets/images/design/P&SD_usluga2/03_strategia.webp')
			},
			{
				title: 'Tworzenie innowacji',
				text: 'Powiedzieć, że jest się innowacyjnym, jest łatwo: niewielu potrafi to jednak wprowadzić w życie. Tworzenie innowacyjnych produktów oraz rozwiązań zaczyna się od głębokiego procesu badawczego oraz poznawczego. <br><br>Posiadamy zasoby, możliwości oraz doświadczenie, które pozwala nam sprostać wyzwaniom tworzenia innowacyjnych, nietypowych produktów, włączając w to nasze doświadczenie technologiczne i holistyczne podejście do podejmowanych wyzwań.',
				image: require('@/assets/images/design/P&SD_usluga2/04_tworzenie_innowacji.png')
			},
		],
	},
	{
		id: routePath.DESIGN_FIX.path,
		title: 'Kiedy należy poprawić użyteczność?',
		list: [
			{
				text: 'Pomimo intensywnie podejmowanych działań marketingowych i sprzedażowych klienci nie mają pełnej świadomości oferty firmy. Chociaż w działaniach bezpośrednich wyrażają zainteresowanie i chęć zakupu, konwersja produktu cyfrowego tego nie odzwierciedla;'
			},
			{
				text: 'Pojawiający się chaos w strukturach, konieczność dodania nowych funkcjonalności lub elementów, które są niezbędne do dalszego funkcjonowania;'
			},
			{
				text: 'Rosnące wydatki na marketing i zwiększająca się ilość osób zainteresowanych produktem, ale mimo to niewielka albo nienaturalnie powoli wzrastająca konwersja;'
			},
			{
				text: 'Chęć poprawy i optymalizacji poszczególnych modułów lub ścieżek użytkowników w konkretnych częściach produktu cyfrowego;'
			},
			{
				text: 'Produkt po czasie przestał odpowiadać potrzebom, do jakich został stworzony, ponieważ profil działania firmy uległ zmianie;'
			},
			{
				text: 'Nadmierne obłożenie działu obsługi klienta i powtarzające się konkretne zapytania;'
			},
			{
				text: 'Spadająca konwersja, zwiększająca się liczba odbić i porzuconych koszyków, zmniejszający się czas pobytu na stronie;'
			},
			{
				text: 'Czynniki zewnętrzne wymuszają zmiany, ale nie ma możliwości stworzenia produktu zupełnie na nowo.'
			}
		],

		title2: 'Jak to robimy?',
		list2: [
			'Testujemy dotychczasową architekturę informacji, aby sprawdzić, czy produkt komunikuje to, co powinien komunikować, i czy gradacja informacji jest czytelna i skuteczna pod kątem marketingowym i sprzedażowym.',
			'Analizujemy warstwę wizualną, w tym jej ogólny odbiór, np. wielkość fontów, kontrasty, kolorystykę, dobór zdjęć, wyróżnienia CTA, widoczność buttonów itp.',
			'Sprawdzamy nawigację i strukturę produktu oraz weryfikujemy heurystyki powiązane z opcjami produktu.',
		],

		product: {
			title: 'Nieskończony produkt … Co dalej?',
			text: 'Realizacja produktu cyfrowego bywa skomplikowanym przedsięwzięciem i czasem łatwo utonąć w meandrach hierarchii działań i mglistych procesów. Zaburzone w dobrej woli procesy realizacji, które pozornie mają przyspieszyć projekt bądź uzyskać jego finansowanie, często kończą się koniecznością jednoczesnej weryfikacji dotychczasowych założeń i dostosowania produktu do elementów, które zostały już zaakceptowane i zweryfikowane, a czasem nawet dopuszczone do użytku przez dział sprzedaży. W najbardziej ekstremalnych przypadkach kończy się to produktem tworzonym latami, który nigdy nie zostaje opublikowany, ponieważ po kilku latach realizacji zdążył się zdezaktualizować i nie spełnia już celów biznesowych.',
			subtext: 'Spotykaliśmy się z taką sytuacją niejednokrotnie i dzięki szerokiemu wachlarzowi narzędzi i metodyk pomożemy Wam wyjść z niej obronną ręką w trzech krokach:',
			list: [
				{
					number: '01',
					text: 'Sprawdzimy, jaki zakres prac został wykonany do tej pory i porównamy go z wymaganiami projektowymi.'
				},
				{
					number: '02',
					text: 'Opracujemy strategię wyjścia z problemu, przedstawiając i rekomendując potencjalne rozwiązania. Wspólnie zadecydujemy o dalszych krokach.'
				},
				{
					number: '03',
					text: 'Zrealizujemy projekt, dopasowując go do wcześniej przygotowanych założeń i strategii.'
				},
			]
		}
	},
	{
		id: routePath.DESIGN_PRODUCT.path,
		list: [
			{
				title: 'Product Design',
				text: 'Produktem cyfrowym w rozumieniu projektowo-biznesowym jest między innymi każda strona internetowa, aplikacja mobilna, system czy platforma. <br><br>Obecnie rzadkością jest tworzenie zamkniętych i stworzonych raz na zawsze stron internetowych, które stanowiłyby wyłącznie wizytówkę firmy, bez używania ich jako narzędzie sprzedażowo-marketingowe. <br><br>Możliwości technologiczne oferują nam szeroki wachlarz możliwości takich jak zbieranie leadów, sprzedaż e-commerce, tworzenie mierzalnych kampanii reklamowych skierowanych do konkretnej grupy odbiorców, analityki czy możliwości otrzymywania feedbacku od odbiorców. Dzisiejsze produkty cyfrowe stanowią rozbudowane narzędzia sprzedaży wpływające realnie na pozycję na rynku, dlatego tak istotne jest właściwe ich dobranie w szerokim spojrzeniu na aspekt całej działalności i ekspertyz, a nie wyłącznie naszych wyobrażeń.',
				image: require('@/assets/images/design/P&SD_usluga4/02_product_design.webp')
			},
			{
				title: '',
				text: 'Budujemy produkty cyfrowe i rozwiązania dla firm, przeprowadzając je bezpiecznie przez cały proces projektowy: od idei po zakończony, oddany do użytku produkt. Dzięki naszemu szerokiemu wachlarzowi kompetencji i wieloletniemu doświadczeniu wiemy, jak podejść do tematu tak, aby było to możliwie najbardziej skuteczne. <br><br>Nasz proces projektowy składa się z poszczególnych faz takich jak badania, warsztaty, koncepcje i prototypowanie, aż po wdrożenie i testowanie. Podczas procesu tworzymy rozwiązania, które ułatwią użytkownikowi dotarcie do poszczególnych informacji, a tym samym wykonanie określonego przez cele biznesowe działania. <br><br>Podnosimy konwersję, zwiększamy ROI i budujemy pozytywne doświadczenia wśród konsumentów, którzy nie tylko sami chętnie powrócą do usługi, ale również polecą ją swoim znajomym.',
				image: require('@/assets/images/design/P&SD_usluga4/03_product_design.webp')
			},
			{
				title: 'Service Design',
				text: 'Projektujemy usługi w szerokim spojrzeniu na całość działalności, a nie jedynie na produkt. Niebagatelne znaczenie ma kontekst użycia, sposób dostarczania usługi oraz doświadczenia użytkownika. <br><br>Projektowanie usług zawiera w sobie metodyki i podejście znane z Product Design, jednak poszerzone o poszukiwanie informacji o tym, dlaczego użytkownicy korzystają z usługi, w jaki sposób z niej korzystają, kiedy podejmują wybór o jej użyciu, dlaczego ich wybór padł dokładnie na tę usługę i jakie czynniki na to wpłynęły. <br><br>Bierzemy pod uwagę wszystkie konteksty związane z celami biznesowymi oraz potencjalnym barierami w postaci braku zasobów, opóźnień w dostawach, specyfiki komponentów lub procesów ich wytwarzania, dostępności, sposobu komunikacji itp. <br><br>Sprawdzamy i udoskonalamy produkt poprzez testy i iteracje zgodnie z metodyką Design Thinking, ponieważ zakładamy, że każda zmiana musi zostać zweryfikowana i potwierdzona jako skuteczne rozwiązanie przez użytkowników końcowych, tak aby produkt mógł pełnić rolę narzędzia sprzedaży.',
				image: require('@/assets/images/design/P&SD_usluga4/04_service_design.webp')
			},
		],
	},
	{
		id: routePath.DESIGN_DEVELOPMENT.path,
		list: [
			{
				title: 'Web',
				text: 'Realizujemy kompleksowo strony internetowe i aplikacje, zarówno proste landing page produktowe, jak i skomplikowane wielopoziomowe platformy. Przeprowadzamy skutecznie i bezproblemowo przez cały proces produkcji: od koncepcji, przez strategię, design i prototypowanie, aż po wdrożenie.',
				image: require('@/assets/images/design/P&SD_usluga5/02_web.webp')
			},
			{
				title: 'Mobile',
				text: 'Przykładamy ogromną wagę do tworzenia produktów przyjaznych konsumentom urządzeń mobilnych z uwagi na bardzo duży udział rynkowy tego medium. Każda tworzona przez nas strona jest responsywna (RWD), co ułatwia odbiorcom korzystanie z produktów.',
				image: require('@/assets/images/design/P&SD_usluga5/03_mobile.webp')
			},
			{
				title: 'Aplikacje',
				text: 'Tworzymy aplikacje na platformy iOS oraz Android, a także Progressive Web App (PWA). Tworzymy aplikacje, które odpowiadają realnym potrzebom biznesowym, a także korespondują z innymi produktami cyfrowymi w celu zmaksymalizowania Waszego ROI.',
				image: require('@/assets/images/design/P&SD_usluga5/04_aplikacje.webp')
			},
			{
				title: 'Front-end',
				text: 'Zamieniamy designerskie projekty w realnie funkcjonujące produkty! Front-end odpowiada za to, z czym użytkownicy wchodzą w interakcje na stronie i w aplikacji. Dbamy o nienaganną jakość kodu, tak aby produkt był atrakcyjny zarówno wizualnie, jak i użytkowo.',
				image: require('@/assets/images/design/P&SD_usluga5/05_frontend.webp')
			},
			{
				title: 'Back-end',
				text: 'Tworzymy to, czego użytkownik nie widzi: zaplecze techniczne produktów cyfrowych. Rozwiązania po stronie serwera, panele administracyjne i wiele innych rzeczy, które odpowiadają za wydajność produktu i jego połączenie z bazą danych.',
				image: require('@/assets/images/design/P&SD_usluga5/06_backend.webp')
			},
			{
				title: 'Analityka i optymalizacja',
				text: 'Nasze działania nie kończą się wraz z finalizacją projektu. Uruchamiamy narzędzia analityczne, obserwujemy, co się dzieje i jesteśmy gotowi nie tylko na optymalizowanie ścieżek, ale również rozbudowywanie produktu o kolejne funkcje w zależności od potrzeb. Analityka i badania pozwalają nam zadbać o to, aby cykl życia produktu nie przekroczył fazy jego dojrzałości i wciąż na siebie zarabiał.',
				image: require('@/assets/images/design/P&SD_usluga5/07_analityka.webp')
			},
		],
	},
	{
		id: routePath.DESIGN_TRANSFORM.path,
		list: [
			'Wspieramy firmy w procesie transformacji cyfrowej poprzez budowanie rozwiązań web, aplikacji i systemów.',
			'Upraszczamy użyteczność w systemach (ERP, SaaS) poprzez analizę procesów i ścieżek użytkowników. Proponujemy możliwe ułatwienia w ramach systemów.',
			'Analizujemy problemy wynikające z niedopasowania systemów do pracy poszczególnych grup i opracowujemy możliwe alternatywy.',
			'Prowadzimy warsztaty wewnętrzne, które pomagają w zrozumieniu oraz uszeregowaniu procesów.',
			'Wyszukujemy obszary, jakie nie zostały zagospodarowane, a które można włączyć do transformacji cyfrowej.',
		]
	},
	{
		id: routePath.DESIGN_IOT.path,
		list: [
			{
				title: 'Interfejsy',
				text: 'Tworzymy interfejsy zarówno dla artykułów gospodarstwa domowego, jak i wyświetlaczy przemysłowych. Uwzględniamy ergonomię urządzenia, sposób użycia oraz możliwości techniczne wyświetlaczy.',
				image: require('@/assets/images/design/P&SD_usluga7/02_interfejsy.webp')
			},
			{
				title: 'Aplikacje',
				text: 'Projektujemy aplikacje uzupełniające urządzenia IoT, które pozwalają zarządzać nimi zdalnie.',
				image: require('@/assets/images/design/P&SD_usluga7/03_aplikacje.webp')
			},
			{
				title: 'Digital POS',
				text: 'Zajmujemy się również projektowaniem architektury informacji oraz interfejsów dla customowych multimedialnych kiosków, paneli i wyświetlaczy.\n',
				image: require('@/assets/images/design/P&SD_usluga7/04_digital_pos.webp')
			},
		],
	},
	{
		id: routePath.DESIGN_SUPPORT.path,
		list: [
			{
				title: 'Zarządzanie strategiczne w projekcie',
				text: 'Zarządzamy procesem tworzenia produktu. Wskazujemy, jakie zasoby uruchomić na poszczególnych etapach oraz jak je zespolić w całość. Określamy również, które elementy są kluczowe w projekcie oraz przewidujemy momenty krytyczne. Dzięki tej wiedzy i odpowiedniej reakcji wspomagamy firmy w uniknięciu pętli rozwiązywania nieistotnych problemów oraz opóźnień w realizacji, które wynikają z nieodpowiedniego zarządzania.',
				image: require('@/assets/images/design/P&SD_usluga8/02_zarzadzanie.webp')
			},
			{
				title: 'Tworzenie i wdrażanie planów działania',
				text: 'Ustalamy plany działania tak, aby sprawnie i efektywnie dostarczyć produkt. W zależności od potrzeb koordynujemy zespół wewnętrzny klienta bądź dołączamy do istniejącego w formie dodatkowych zasobów. Stanowimy wsparcie, które zapewni dostarczenie realnego rozwiązania problemu.',
				image: require('@/assets/images/design/P&SD_usluga8/03_plany_dzialania.webp')
			},
			{
				title: 'Konsultacje',
				text: 'Służymy pomocą we wszystkich kwestiach związanych z tworzeniem produktu cyfrowego. Rozumiemy, że jego realizacja może stanowić wyzwanie, dlatego w dowolnym momencie jesteśmy gotowi wyjaśnić Wam wszystko, co jest konieczne, włączając w to aktualny etap działań, jak i zarekomendować dalsze kroki.',
				image: require('@/assets/images/design/P&SD_usluga8/04_konsultacje.webp')
			},
		],
	},
]

export {design}
