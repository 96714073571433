<template>
	<section class="design">
		<div class="design__content">
			<section class="design__subpage">
				<section v-if="content.id === '/transformacje-cyfrowe'" class="transform">
					<ul class="transform__list">
						<li
							v-for="(item, index) in content.list"
							:key="index"
							class="transform__list-item">
							<p class="p-style" v-html="item"/>
						</li>
					</ul>
				</section>

				<ProductImprovement v-else-if="content.id === '/poprawa-uzytecznosci'" :content="content"/>
				<SubPage v-else :content="content" subpage="design"/>

				<div class="check__process">
					<h2>Zobacz, w jaki sposób realizujemy projekty </h2>
					<Button
						buttonStyle="primary"
						:additionalElement="true"
						additionalElementStyle="arrowInCircle"
						:whiteArrow="false"
						buttonText="Etapy tworzenia produktu cyfrowego"
						buttonLink="/service-design-proces"
					></Button>
				</div>
			</section>

			<FAQComponent currentButton="produkt"/>
		</div>
	</section>
</template>

<script src="./component.js"></script>

<style lang="sass" scoped>
.check__process
	width: 144em
	margin: 160px auto 90px
	padding-left: 211px
	height: 100px

	@include view('md')
		width: 100%
		padding: 60px 100px
		margin: 0 auto
		height: unset

	@include view('sm')
		width: 100%
		padding: 60px 33px 0
		margin: 0 0 0 0

	h2
		font-size: 28px
		font-weight: $weight--light-plus
		margin-bottom: 30px

		@include view('sm')
			font-size: 20px

.transform
	width: 144em
	margin: auto
	padding: 112px 0 0 211px

	@include view('md')
		width: 100%
		padding: 60px 50px

	@include view('sm')
		width: 100%
		padding: 60px 33px

	@include view('sm')
		width: 100%

	&__list
		display: flex
		flex-wrap: wrap
		gap: 5rem 12rem

		&-item
			width: 270px

::v-deep .main__faq
	@include view('md')
		padding: 60px 100px

	@include view('sm')
		padding: 60px 33px
</style>
