import {design} from '@/data/design'
import FAQComponent from '@/components/FAQComponent/index'
import SubPage from '@/components/SubPage/SubPage.vue'
import ProductImprovement from "./content/ProductImprovement";

export default {
	name: 'Design',
	components: {
		SubPage,
		FAQComponent,
		ProductImprovement
	},
	watch: {
		'$route.fullPath': {
			handler() {
				let currRoute = this.$route.fullPath

				if (currRoute.includes('?')) {
					currRoute = currRoute.substring(0, currRoute.indexOf('?'))
					this.$store.state.designContent = this.design.find(x => x.id === currRoute)
				}

				this.$store.state.designContent = this.design.find(x => x.id === currRoute)
			}
		}
	},
	beforeCreate() {
		this.$nextTick(() => {
			this.handleContent()
		})
	},
	computed: {
		content() {
			if (this.$store.state.designContent) {
				return this.$store.state.designContent
			}
		}
	},
	data() {
		return {
			design,
		}
	},
	methods: {
		handleContent() {
			let currRoute = this.$route.fullPath

			if (currRoute.includes('?')) {
				currRoute = currRoute.substring(0, currRoute.indexOf('?'))
				this.$store.state.designContent = this.design.find(x => x.id === currRoute)
			} else {
				this.$store.state.designContent = this.design.find(x => x.id === currRoute)
			}
		}
	},
}
